import axiosRequest from "@/utils/axiosRequest";

export function listMaster(data) {
  return axiosRequest({
    url: `/master/getList`,
    data,
    method: "post"
  });
}

export function getDetail(data) {
  return axiosRequest({
    url: `/master/getDetail`,
    data,
    method: "post"
  });
}

export function addMaster(data) {
  return axiosRequest({
    url: `/master/addMaster`,
    data,
    method: "post"
  });
}

export function updateMaster(data) {
  return axiosRequest({
    url: `/master/updateMaster`,
    data,
    method: "post"
  });
}

export function deleteMaster(data) {
  return axiosRequest({
    url: `/master/deleteMaster`,
    data,
    method: "post"
  });
}

export function getRelationList(data) {
  return axiosRequest({
    url: `/master/relationList`,
    data,
    method: "post"
  });
}

export function getSelMaster(data) {
  return axiosRequest({
    url: `/master/getSelMaster`,
    data,
    method: "post"
  });
}

export function addRelation(data) {
  return axiosRequest({
    url: `/master/addRelation`,
    data,
    method: "post"
  });
}

export function deleteRelation(data) {
  return axiosRequest({
    url: `/master/deleteRelation`,
    data,
    method: "post"
  });
}

export function getParentList(data) {
  return axiosRequest({
    url: `/master/parentList`,
    data,
    method: "post"
  });
}

export function getParentById(data) {
  return axiosRequest({
    url: `/master/getParent`,
    data,
    method: "post"
  });
}